.header {
  text-align: center;
}

.QRCodeGenerator {
  flex: 1;
}

.QRCodeImage {
  padding: 25px;
}
